import React from "react";
import styled from "styled-components";
import AliImage from "../images/ali_s.jpeg";
import ChristineImage from "../images/christine.jpeg";
import OzgeImage from "../images/ozge.jpeg";
import LinkedInIcon from "../images/linkedin-icon.png";
import aboutUsBackground from '../images/about_us_background.jpeg';
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const TeamSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;
  overflow: hidden; // Ensures no overflow causing horizontal scrolling
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0 10px; // Adds padding to prevent content from touching the edges
  }
`;

const MemberContainer = styled.div`
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%; // Full width for each member to fit the mobile screen properly
  }
`;

const Heading = styled.h2`
  color: #111d57;
  font-size: 36px;
  margin-bottom: 30px;
  font-family: "Source Sans Pro";

  @media (max-width: 768px) {
    font-size: 28px; // Slightly smaller font size for mobile
  }
`;

const Description = styled.h3`
  color: #666;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 40px;
  line-height: 1.5;
  font-family: "Source Sans Pro";

  @media (max-width: 768px) {
    font-size: 18px; // Smaller font size for better readability on mobile
  }
`;

const Image = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 110px;
    height: 110px; 
  }
`;

const Name = styled.h3`
  color: #111d57;
  font-weight: bold;
  font-size: 22px;
  font-family: "Source Sans Pro";

  @media (max-width: 768px) {
    font-size: 18px; // Smaller font size for mobile
  }
`;

const Title = styled.h2`
  color: #999;
  font-size: 18px;
  font-family: "Source Sans Pro";
`;

const Bio = styled.p`
  color: #666;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 16px; // Reduced font size to prevent overflow
  }
`;

const LinkedInLogo = styled.img`
  height: 28px;
`;

// New Section Styles
const Section = styled.section`
  display: flex;  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  padding: 30px;
  background-image: url(${aboutUsBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw; // Ensures the background covers the full width of the screen
  @media (max-width: 768px) {
    height: auto;
    padding: 15px 20px;
  }
`;

const JobContainer = styled.div`
  width: 60%; // Increased width to make containers wider
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const RoleName = styled.h3`
  color: #111d57;
  font-weight: bold;
  font-size: 26px; // Slightly larger font size for role name
  font-family: "Source Sans Pro";
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const JobDescription = styled.p`
  color: #666;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; // Centers the button
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #284389;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  width: 220px;
  height: 45px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #111d57;
  }
`;

const AboutUsTeam: React.FC = () => {
  return (
    <>
      <TeamSection>
        <GlobalStyles /> {/* Include global styles */}
        <div>
          <Heading>Our Team</Heading>
          <Description>
            We believe clean technologies can save consumers hundreds of dollars
            on their energy spending and combat climate change.
          </Description>
        </div>
        <ContentContainer>
          <MemberContainer>
            <Image src={OzgeImage} alt="Ozge Islegen-Wojdyla" />
            <Name>Ozge Islegen-Wojdlya</Name>
            <Title>CTO & Co-Founder</Title>
            <Bio>
              Former Assistant Professor at Northwestern and Stanford PhD focused on residential energy research.
              Previously led statistical interventions and testing as Senior Data Scientist at Instacart.
            </Bio>
            <a href="https://www.linkedin.com/in/ozge-islegen-wojdyla/" target="_blank" rel="noopener noreferrer">
              <LinkedInLogo src={LinkedInIcon} alt="LinkedIn" />
            </a>
          </MemberContainer>
          <MemberContainer>
            <Image src={AliImage} alt="Ali Sarilgan" />
            <Name>Ali Sarilgan</Name>
            <Title>Co-Founder</Title>
            <Bio>
              Previously led corporate strategy team at a consumer company and
              advised clean technology providers. Studied Management Science &
              Engineering at Stanford.
            </Bio>
            <a href="https://www.linkedin.com/in/alisarilgan" target="_blank" rel="noopener noreferrer">
              <LinkedInLogo src={LinkedInIcon} alt="LinkedIn" />
            </a>
          </MemberContainer>
          <MemberContainer>
            <Image src={ChristineImage} alt="Christine Manegan" />
            <Name>Christine Manegan</Name>
            <Title>Founding Engineer</Title>
            <Bio>
              Former data and infrastructure engineer at Snapchat,
              and hardware-to-software integration engineer at Apple.
              Studied Computer Science at Stanford.
            </Bio>
            <a href="https://www.linkedin.com/in/christine-astoria-manegan-6a4812159/" target="_blank" rel="noopener noreferrer">
              <LinkedInLogo src={LinkedInIcon} alt="LinkedIn" />
            </a>
          </MemberContainer>
        </ContentContainer>
      </TeamSection>
      
      {/* New Section */}
      <Section>
        <JobContainer>
          <Heading>Join Our Team</Heading>
          <Description>
            Browse roles we are currently hiring for and join us in making our energy industry cheaper and greener.
          </Description>
          <RoleName>Founding Engineer</RoleName>
          <JobDescription>
            Work directly with the founders across the full-stack to grow as an engineering leader and help make green energy the norm in the US.
          </JobDescription>
          <Button
            onClick={() => window.open("https://dinlmon2rl8.typeform.com/to/TBw7Bvql", "_blank")}
          >
            Apply Now
          </Button>
        </JobContainer>
      </Section>
    </>
  );
};

export default AboutUsTeam;
