import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LocalizationProvider, MobileDatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMediaQuery } from '@mui/material';
import { handleStartStripe } from '../startStripe';
import { Helmet } from 'react-helmet';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

// Styled components
const OuterContainer = styled.div`
  background-color: #e6f7ff; /* Light blue background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
`;

const Whitespace = styled.div`
  background-color: #ffffff; /* White background */
  padding: 20px; /* Padding around the content */
  border-radius: 20px; /* Rounded corners */
  max-width: 90%; /* Maximum width of the container */
  text-align: left; /* Align text to the left */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the left */
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: bold;
  color: #111d57;
  line-height: 1.5;
  padding-top: 5px;
  font-family: "DM Sans";

  @media (max-width: 768px) {
    font-size: 20px;
    padding-top: -35px;
    padding-bottom: -25px;
    line-height: 1.5;
  }
`;

const Subtitle = styled.p`
  color: #666;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  padding-top: 2px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding-top: 0px;
    line-height: 1.5;
  }
`;

const ChangeText = styled.p`
  color: #666;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  padding-top: 2px;

  @media (max-width: 768px) {
    font-size: 16px;
    padding-top: 0px;
    line-height: 1.5;
  }
`;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px; /* Add margin to the top */
`;

const DatePickerComponent = styled.div`
  flex: 1; /* Take up remaining space */
`;

const ContinueButton = styled.button`
  background-color: #284389;
  color: white;
  cursor: pointer;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  padding-left: 20px;
  width: 190px;
  height: 54px;
  margin-left: -1px;
  &:hover {
    background-color: #111d57;
  }
  border-radius: 4px; /* Adjust the value as needed for the desired roundness */
  @media (max-width: 768px) {
    padding-left: 10px;
    font-size: 15px;
    width: 145px;
    height: 48px;
    margin-left: -1px;
  }
`;

const CustomizeQuote: React.FC = () => {
    const today = new Date();
    const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

    const [selectedDate, setSelectedDate] = useState<Date | null>(today);
    const isMobile = useMediaQuery('(max-width:768px)');

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email") as string;

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);

    const handleContinue = () => {
        clearTimeout(timeoutIdRef.current); 
        let startDate = selectedDate;
        console.log("selected default date", selectedDate);
        if (startDate === null) {
           startDate = today;
        }
        handleStartStripe(email, startDate);
    };

    useEffect(() => {
      const resetTimer = () => {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current); // Clear the previous timeout if any
        }
        timeoutIdRef.current = setTimeout(() => {
          // Your action to be performed after 30 seconds of inactivity
          console.log('User has been inactive for 30 seconds.');
          handleContinue();
        }, 30000); // 30 seconds in milliseconds
      };
      resetTimer();
  
      // Cleanup function to clear timeout on component unmount or before next effect run
      return () => {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
  


    return (
      <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
     <PixelTracking />
   </Helmet> 

    <main>
        <OuterContainer>
          <Whitespace>
            <Title>Your Subscription Start Date:</Title>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleContinue();
                }}>
                  <DatePickerContainer>
                    <DatePickerComponent>      
                      {isMobile ? (
                          <MobileDatePicker
                              label="Start Date"
                              value={selectedDate}
                              onChange={handleDateChange}
                              minDate={today}
                              maxDate={maxDate}
                              // inputFormat="MM/dd/yyyy"
                              disablePast
                              // Uses TextField as default input without renderInput prop
                              // renderInput={(params) => <TextField {...params} fullWidth />}
                          />
                      ) : (
                          <DesktopDatePicker
                              label="Start Date"
                              value={selectedDate}
                              onChange={handleDateChange}
                              minDate={today}
                              maxDate={maxDate}
                              // inputFormat="MM/dd/yyyy"
                              disablePast
                              // Uses TextField as default input without renderInput prop
                              // renderInput={(params) => <TextField {...params} fullWidth />}
                          />
                      )} 
                    </DatePickerComponent>   
                    <ContinueButton type="submit">Continue</ContinueButton>
                  </DatePickerContainer>
                </form>
            </LocalizationProvider>
            <Subtitle>
                Begin saving today by clicking "Continue" to start your subscription today! <br /><br />
                Your 12-month EcoTrove subscription will begin on this start date and allow EcoTrove to pay for your next 12 due PG&E bills. You can cancel anytime.
                You will be redirected to Stripe to complete your payment in 30 seconds unless you select a different start date and click “Continue”.
            </Subtitle>
            <ChangeText>
                We selected your earliest possible start date, you can choose another date if you wish.
            </ChangeText>
          </Whitespace>
        </OuterContainer>
        </main>
    </>
    );
};

export default CustomizeQuote;
