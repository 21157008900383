import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import { useNavigate } from "react-router-dom";

const Container = styled.section`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Stack items vertically */
  min-height: 100vh; /* Ensures that the container fills the whole screen */
  background-color: #e6f7ff; /* Specified background color */
`;



const Button = styled.button`
  padding: 0 30px; /* Adjusted padding to remove extra height from top/bottom */
  border: none;
  border-radius: 10px;
  height: 60px; /* Keeps the height fixed */
  width: 200px; /* Keeps the width fixed */
  background-color: #111d57;
  color: white;
  font-size: 18px; /* Increased font size */
  cursor: pointer;
  margin-left: 25px;
  font-family: "Source Sans Pro";
  font-weight: bold;
  display: flex; /* Flexbox for centering */
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #80AA7B; 
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';

  const navigateToLoadDocusign = (email: string) =>  {
    // Construct the full URL
    const loadDocusignUrl = `/load-docusign?email=${encodeURIComponent(email)}`;
    // Use the navigate function to navigate to the constructed URL
    navigate(loadDocusignUrl);
  }

  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
    <PixelTracking />
   </Helmet> 
    <main>
    <Container>
    <Button onClick={() => navigateToLoadDocusign(email)}> Continue to Docusign
   </Button>
</Container>
</main>
</>
  );
};

export default PaymentSuccess;
