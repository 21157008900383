import React, { useState, useEffect } from 'react';
import { supabase } from "../supabaseClient"; // Assuming you have a supabase client configured
import styled from "styled-components";
import { Helmet } from 'react-helmet';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import { useNavigate } from "react-router-dom";
import background5 from '../images/background5.jpeg';
import { Turnstile } from '@marsidev/react-turnstile';

const RoundedRectangle = styled.div`
  border: 2px solid #253D80;
  border-radius: 15px;
  padding: 1rem;
  margin: 1rem 0;
  width: 80%;
`;

const Button = styled.button`
  width: 200px; /* Fixed width for both buttons */
  padding: 10px 30px;
  border: 3px solid #111d57;
  border-radius: 5px;
  color: white;
  margin-top: -100px;
  cursor: pointer;
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 0 10px; /* Adjusted margin for side-by-side buttons */
  &:hover {
    background-color: #111d57;
  }
`;

const GetQuoteButton = styled(Button)`
  background-color: #284389;
`;

const TryAgainButton = styled(Button)`
  background-color: #e6f7ff;
  color: #111d57;
  border: 3px solid #111d57;

  &:hover {
    background-color: #111d57;
    color: white;
}`;

// Define the props interface for the Overlay component
interface OverlayProps {
  isVisible: boolean;
}

// Styled component with TypeScript
const Overlay = styled.div<OverlayProps>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

// Styled component without additional props
const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  text-align: center;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;
  
  @media (max-width: 768px) {
    background: url(${background5}) no-repeat center center fixed;
    background-size: cover;
    & > div {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
    &::after {
      content: '';
      background: url(${background5}) no-repeat center center fixed;
      background-size: cover;
      flex: 1;
    }
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  padding-top: 80px; 
  
  @media (min-width: 769px) {
    width: 50vw;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  font-size: 20% larger; /* Increased by 20% */
`;

const CreateAccountButton = styled.button`
  border-radius: 10px;
  background-color: #80AA7B;
  color: white;
  font-size: 100%;
  padding: 8px 25px; 
  border: none; 
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #253D80;
    transform: scale(1.05);
  }
`;

const CreateAccount = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  let originalEmail = urlParams.get("email") as string || '';

  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState(originalEmail);
  const [fullName, setFullName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('')

  const validateEmail = async (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!emailRegex.test(email)) {
      return false;
    }

    const domain = email.split('@')[1];
    try {
      const lookup = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
      const result = await lookup.json();
      return result.Answer !== undefined;
    } catch (e) {
      console.error("DNS lookup failed:", e);
      return false;
    }
  };

  useEffect(() => {
    if (originalEmail !== '') {
      //check if email is in user profiles at all
      fetchUserProfile(email);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// Empty dependency array ensures this effect runs only once after component mounts

  async function fetchUserProfile(email: string) {
    setLoading(true);
    try {
      const createAccountURL = window.location.href;

      await supabase
        .from('user-profiles')
        .update({
          create_account_url: createAccountURL,
        }).eq("email", originalEmail);

      const { data, error } = await supabase
        .from('user-profiles')
        .select()
        .eq('email', email)
        .single();

      if (error) {
        throw error;
      }

      setFullName(data.name || '');
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setLoading(false);
    }
  }

  const emailInUserProfiles = async (email: string) => {
    const { data, error } = await supabase
    .from('user-profiles')
    .select()
    .eq('email', email)
    .single();
    if (error) {
      console.error('Error checking email:', error);
      return false;
    }
    return !!data; // Returns true if email exists, false otherwise
  }

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const handleGetQuote = () => {
    navigate('/get-quote');
  };

  async function handleSignUp(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setClicked(true);
    setLoading(true);
    setErrorMessage('');

    try {
      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
      if (!passwordRegex.test(password)) {
        setErrorMessage("Password must be at least 8 characters long and contain at least one uppercase letter, one digit, and one symbol.");
        setLoading(false);
        setClicked(false);
        return;
      }

      if (password !== confirmPassword) {
        setErrorMessage("Passwords do not match. Please try again.");
        setLoading(false);
        setClicked(false);
        return;
      }

      if (!validateEmail(email)) {
        setErrorMessage("Please enter a valid email");
        setLoading(false);
        setClicked(false);
        return;
      }

      //check if email is even in supabase user profiles, if not, we take the user through get quote flow
      const emailExists = await emailInUserProfiles(email);
      if (originalEmail === '' && !emailExists) {
        //show popup options
        // alert("no email found!")
        setIsPopupVisible(true);
        return;
        // return here!
      }
      else if (originalEmail === '') {
        originalEmail = email;
      }

      if (captchaToken === '') {
        setErrorMessage("Please complete hCaptcha");
        return;
      }

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: 'https://ecotrove.com/login-page', 
          captchaToken
        }
      });

      if (error) {
        if (error.code === '23505') { 
          setErrorMessage("User with this email already exists. Please login instead.");
          navigate('/login-page');
          throw error;
        } else {
          setErrorMessage(error.message || "An error occurred. Please try again later.");
        }
        throw error; // Rethrow the error to handle it further if needed
      }

      const user = data?.user;
      const id = user?.id;
      if (user?.identities === undefined || !user?.identities.length) {
        alert("There is already an email associated with this account. Please log in instead.");
        navigate('/login-page');
        return;
      }

      // Update user profiles, service accounts, and all associated tables with user_id
      await Promise.all([
        supabase
          .from('user-profiles')
          .update({
            user_id: id,
            name: fullName,
            active_account: true,
          }).eq("email", originalEmail),

        supabase
          .from('service-accounts')
          .update({
            user_id: id,
          }).eq("email", originalEmail),

          supabase
          .from('user-bills')
          .update({
            user_id: id,
          }).eq("email", originalEmail),

          supabase
          .from('savings-claimed')
          .update({
            user_id: id,
          }).eq("email", originalEmail),

        // Update email if changed
        originalEmail !== email && email !== '' &&
        supabase
          .from('user-profiles')
          .update({
            email: email,
          })
          .eq('email', originalEmail),

        originalEmail !== email && email !== '' &&
        supabase
          .from('service-accounts')
          .update({
            email: email,
          })
          .eq('email', originalEmail),
      ]);

      console.log("handled signup");

      // Redirect to the confirm-email page
      navigate(`/confirm-email?email=${encodeURIComponent(email)}`);

    } catch (error) {
      console.error('Error creating account:', error);
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
      setClicked(false);
    }
  }

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <PixelTracking />
      </Helmet>

      <PageContainer>
        <ContentContainer>
          <h1 style={{ color: '#253D80', textAlign: 'center' }}>
            Create an Account
          </h1>
          <RoundedRectangle>
            <form onSubmit={handleSignUp}>
              <label style={{ fontWeight: 'bold', fontSize: '110%' }}>
                Email:
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{ marginLeft: '10px', fontSize: '110%' }}
                />
              </label>
              <div style={{ marginTop: '10px' }} />
              <label style={{ fontWeight: 'bold', fontSize: '110%' }}>
                Full Name:
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  style={{ marginLeft: '10px', fontSize: '110%' }}
                />
              </label>
              <div style={{ marginTop: '10px' }} />
              <label style={{ fontWeight: 'bold', fontSize: '110%' }}>
                Password:
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{ marginLeft: '10px', fontSize: '110%' }}
                />
              </label>
              <div style={{ marginTop: '10px' }} />
              <label style={{ fontWeight: 'bold', fontSize: '110%' }}>
                Confirm Password:
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  style={{ marginLeft: '10px', fontSize: '110%' }}
                />
              </label>
              <div style={{ marginTop: '10px' }} />
              <Turnstile
              siteKey="0x4AAAAAAAiqGq8fYinFCIYM"
              onSuccess={(token) => {
                setCaptchaToken(token)
              }}
            />
                   {/* Overlay and Popup */}
                <Overlay isVisible={isPopupVisible} />
                {isPopupVisible && (
              <Popup>
                  <p style={{ fontSize: '130%', fontWeight: 'bold' }}>We don't have that email address on file.</p>
                  <p style={{ fontSize: '110%' }}>
                  If you have already signed up for EcoTrove please use the original email you provided. <br /> <br />
                  If not, please get an EcoTrove quote. <br /> <br />
                  </p>
                <GetQuoteButton onClick={handleGetQuote}>Get A Quote</GetQuoteButton>
                <TryAgainButton onClick={handleClosePopup}>Try Again</TryAgainButton>
              </Popup>
                )}
              {/* <div style={{ marginTop: '10px' }} /> */}
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <CreateAccountButton type="submit" disabled={loading}>
                {clicked ? 'Creating Account...' : 'Create Account'}
              </CreateAccountButton>
            </form>
          </RoundedRectangle>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default CreateAccount;