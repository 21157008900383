import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import { handleStartDocusign} from '../startDocusign';
import { Helmet } from 'react-helmet';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Container = styled.section`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Stack items vertically */
  min-height: 100vh; /* Ensures that the container fills the whole screen */
  background-color: #e6f7ff; /* Specified background color */
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: bold;
  color: #111d57;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Subtitle = styled.p`
  color: #666;
  font-size: 24px;
  line-height: 1.5;
  margin-top: 5px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const LoadDocusign = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  handleStartDocusign(email);

  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
    <PixelTracking />
   </Helmet> 
    <main>
    <Container>
    <Title>
        We are routing you to Docusign...
    </Title>
      <Subtitle>
        Please sign our subscriber agreement to authorize EcoTrove to pay your bills with PG&E.
      </Subtitle>
      <Subtitle>
        Please do not exit this screen.
      </Subtitle>
</Container>
</main>
</>
  );
};

export default LoadDocusign;
